import Slide from './slide';

/**
 * @param {Function} callback
 * @param {Number} wait
 * @return {Function}
 */
function $debounce(callback, wait) {
  let timeout;
  return function (e) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(e);
    }, wait);
  };
}
function $updateControlsVisibility() {
  const {
    index
  } = this.currentSlide;
  const [minIndex, maxIndex] = [0, this.slideList.length - this.totalVisibleItems];
  const [buttonPrev, buttonNext] = this.controlsNav.children;
  buttonPrev.disabled = !this.options.fakeInfinite && (index === minIndex || this.slideList.length <= this.totalVisibleItems);
  buttonNext.disabled = !this.options.fakeInfinite && (index === maxIndex || this.slideList.length <= this.totalVisibleItems);
}
function $slidePrev(e) {
  e.preventDefault();
  e.stopPropagation();
  if (this.currentSlide.prev) {
    this.translateTo(this.currentSlide.prev.index);
  } else if (this.options.fakeInfinite) {
    this.translateTo(this.slideList.length - 1);
  } else {
    $updateControlsVisibility.call(this);
  }
}
function $slideNext(e) {
  if (e !== null) {
    // Case of an event call, not autoscroll timer
    e.preventDefault();
    e.stopPropagation();
  }
  if (this.currentSlide.next) {
    this.translateTo(this.currentSlide.next.index);
  } else if (this.options.fakeInfinite) {
    this.translateTo(0);
  } else {
    $updateControlsVisibility.call(this);
  }
}
async function $bindEvents() {
  // Controls
  const [buttonPrev, buttonNext] = this.controlsNav.children;
  buttonPrev.addEventListener('click', $slidePrev.bind(this));
  buttonNext.addEventListener('click', $slideNext.bind(this));
  if (this.options.manualScroll) {
    // Manual slide (touch)
    if (this.options.manualScroll === 'touch' || this.options.manualScroll === 'both') {
      this.bindManualScrollEvent('touchstart', 'touchmove', 'touchend', e => e.touches[0]);
    }

    // Manual slide (mouse)
    if (this.options.manualScroll === 'mouse' || this.options.manualScroll === 'both') {
      this.bindManualScrollEvent('mousedown', 'mousemove', 'mouseup', e => e);
    }
  }

  // Dots navigation
  if (this.dotNavigation) {
    this.bindDotNavigationEvent();
  }

  // Update component once after translation scroll ended
  const scrollEnd = $debounce(() => {
    this.updateComponent();
  }, 100);
  this.container.addEventListener('scroll', scrollEnd, {
    passive: true
  });

  // Resize end detection (for responsive)
  const resizeEnd = $debounce(() => {
    this.translateTo(this.$index, false);
    if (this.dotNavigation) this.initDotNavigation();
    $updateControlsVisibility.call(this);
  }, 100);
  window.addEventListener('resize', resizeEnd, {
    passive: true
  });
}

/**
 * Timer management
 * @param callback callback when timer trigger
 * @param timeout interval duration in ms to trigger callback
 */
function $timer(callback, timeout) {
  let timer = null;

  /**
   * Stop timer if exists (has start). It removes current interval.
   * @returns {$timer} current timer
   */
  this.stop = function () {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
    return this;
  };

  /**
   * Start timer (if not already started). It creates a new interval.
   * @returns {$timer} current timer
   */
  this.start = function () {
    if (!timer) {
      timer = setInterval(callback, timeout);
    }
    return this;
  };

  /**
   * Reset timer by stopping and starting it.
   * @param newTimeout interval duration in ms to trigger callback
   * @returns {$timer} current timer
   */
  this.reset = function (newTimeout = timeout) {
    timeout = newTimeout;
    return this.stop().start();
  };
}
export default class Slider {
  /**
   * @constructor
   * @param {Object} options
   */
  constructor(options) {
    this.options = {
      id: null,
      // {String}
      element: null,
      // {HTMLElement}
      manualScroll: false,
      // {Boolean|String} false|mouse|touch|both
      fakeInfinite: false // {Boolean}
    };
    Object.assign(this.options, options);
    this.root = document.querySelector(`.js-slider-${this.options.id}`) || this.options.element || null;
    if (!this.root) {
      throw new Error('[Slider] Root is null!');
    }
    this.container = this.root.querySelector('.mc-slider__container');
    this.slidesContainer = this.root.querySelector('.mc-slider__slides');
    this.controlsNav = this.root.querySelector('.mc-slider__controls');
    this.autoScroll = this.root.querySelector('.mc-slider__auto-scroll');
    this.playAutoScroll = true;
    this.dotNavigation = this.root.querySelector('.mc-slider__navigation') || null;
    this.slideList = [...this.slidesContainer.children].map(slide => new Slide(slide, this));
    this.slideList.forEach((slide, index, arr) => {
      slide.index = index;
      slide.prev = arr[index - 1] || null;
      slide.next = arr[index + 1] || null;
    });
    this.$index = 0;
    // Auto scroll timer & config
    this.sliderAutoScrollTimer = null;
    this.autoScrollEnabled = this.root.getAttribute("data-autoscroll-enabled");
    this.autoScrollTimeout = parseInt(this.root.getAttribute("data-autoscroll-timeout"));
    this.sliderAutoScrollConfig = {
      enabled: false,
      timeout: 5000
    };
    if (this.autoScrollEnabled && this.autoScrollEnabled === "true") {
      this.sliderAutoScrollConfig.enabled = true;
    }
    if (this.autoScrollTimeout) {
      this.sliderAutoScrollConfig.timeout = this.autoScrollTimeout;
    }
    const promises = [];
    if (this.options.manualScroll) {
      promises.push(import(/* webpackChunkName: "manualScroll" */'./components/ManualScroll.js').then(module => {
        new module.default(Slider);
      }));
    }
    if (this.dotNavigation) {
      promises.push(import(/* webpackChunkName: "dotNavigation" */'./components/DotNavigation.js').then(module => {
        new module.default(Slider);
      }));
    }
    Promise.all(promises).then(() => {
      if (this.dotNavigation) this.initDotNavigation();
      this.updateComponent();
      $bindEvents.call(this);

      // Manage slider autoscroll
      this.sliderAutoScrollTimer = new $timer(() => {
        $slideNext.call(this, null); // Pass null to manage event handling in slideNext
      }, this.sliderAutoScrollConfig.timeout);

      // Start timer if auto scroll enabled
      if (this.sliderAutoScrollConfig.enabled) {
        this.sliderAutoScrollTimer.start();
        this.autoScroll.style.display = "block";
        this.root.setAttribute("aria-live", "off");
        this.initAutoScrollEvent();
      }
    });
  }

  /**
   * @return {HTMLElement[]}
   */
  get fullyVisibleSlideList() {
    return this.slideList.filter(slide => slide.isFullyVisible());
  }

  /**
   * @return {Number}
   */
  get totalVisibleItems() {
    const containerWidth = parseFloat(window.getComputedStyle(this.container).width);
    const [first] = this.slidesContainer.children;
    const firstSlide = new Slide(first, this);
    const coveredWidth = containerWidth % firstSlide.width - (Math.floor(containerWidth / firstSlide.width) - 1) * firstSlide.gap;
    return Math.floor(containerWidth / firstSlide.width) + (coveredWidth < 0 ? -1 : 0);
  }

  /**
   * @return {Slide}
   */
  get currentSlide() {
    const [current] = this.fullyVisibleSlideList;
    if (!current) {
      const visibleWidthList = this.slideList.map(slide => slide.visibleWidth);
      return this.slideList[visibleWidthList.indexOf(Math.max(...visibleWidthList))];
    }
    return current;
  }

  /**
   * @param {Number} index
   * @param {Boolean} transition
   */
  translateTo(index, transition = true) {
    // Reset autoscroll timer if enabled (to avoid quick slide)
    if (this.sliderAutoScrollConfig.enabled) {
      this.sliderAutoScrollTimer.reset();
      if (!this.playAutoScroll) {
        this.sliderAutoScrollTimer.stop();
      }
    }
    if (transition) {
      const direction = this.currentSlide.index > index ? 'right' : 'left';
      this.root.dispatchEvent(new CustomEvent('slide', {
        detail: {
          direction,
          targetSlide: this.slideList[index]
        }
      }));
    }
    this.slideList[index].translateTo(transition);
    this.$index = index;
  }
  updateComponent() {
    this.$index = this.currentSlide.index;
    $updateControlsVisibility.call(this);
    if (this.dotNavigation) this.updateDotNavigation();
  }

  /**
   * Initializing Auto scroll event
   */
  initAutoScrollEvent() {
    const playButton = this.autoScroll.querySelector('.play');
    const stopButton = this.autoScroll.querySelector('.stop');
    playButton.style.display = "none";
    stopButton.style.display = "block";
    const toggleAutoScrollControls = () => {
      playButton.style.display = playButton.style.display === "none" ? "block" : "none";
      stopButton.style.display = stopButton.style.display === "none" ? "block" : "none";
    };

    // stop the timer
    const hoverSlideStart = () => {
      if (this.playAutoScroll) {
        this.sliderAutoScrollTimer.stop();
      }
    };

    // restart the timer
    const hoverSlideStop = () => {
      if (this.playAutoScroll) {
        this.sliderAutoScrollTimer.start();
      }
    };
    const clickPlayAutoScroll = () => {
      this.playAutoScroll = true;
      toggleAutoScrollControls();
      stopButton.focus();
      this.root.setAttribute("aria-live", "off");
      this.sliderAutoScrollTimer.start();
    };
    const clickStopAutoScroll = () => {
      this.playAutoScroll = false;
      toggleAutoScrollControls();
      playButton.focus();
      this.root.setAttribute("aria-live", "polite");
      this.sliderAutoScrollTimer.stop();
    };
    this.slideList.forEach(slide => {
      slide.node.addEventListener('mouseover', hoverSlideStart);
      slide.node.addEventListener('mouseout', hoverSlideStop);
    });
    this.autoScroll.querySelector('.play').addEventListener('click', clickPlayAutoScroll);
    this.autoScroll.querySelector('.stop').addEventListener('click', clickStopAutoScroll);
  }
}