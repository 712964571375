export default class Slide {
  /**
   * @constructor
   * @param {HTMLElement} node
   * @param {Slider} slider
   */
  constructor(node, slider) {
    this.node = node;
    this.slider = slider;
    this.node.classList.add('mc-slider__slide');
    this.prev = null;
    this.next = null;
    this.index = null;
  }

  /**
   * @return {Number}
   */
  get width() {
    return parseFloat(window.getComputedStyle(this.node).width);
  }

  /**
   * @return {Number}
   */
  get gap() {
    return parseInt(window.getComputedStyle(this.node).marginRight, 10) || this.prev && this.prev.gap || 0;
  }

  /**
   * @param {Boolean} transition
   */
  translateTo(transition = true) {
    const scrollValue = this.index * this.width + this.index * this.gap;
    this.slider.container.style[transition ? 'removeProperty' : 'setProperty']('scroll-behavior', 'auto');
    this.slider.container.scrollLeft = scrollValue;
    this.node.focus();
  }

  /**
   * @return {Boolean}
   */
  isFullyVisible() {
    const containerBBox = this.slider.container.getBoundingClientRect();
    const bbox = this.node.getBoundingClientRect();
    return Math.floor(bbox.x) >= Math.floor(containerBBox.x) && Math.floor(bbox.x) + Math.floor(bbox.width) <= Math.floor(containerBBox.x) + Math.floor(containerBBox.width);
  }

  /**
   * @return {Number}
   */
  get visibleWidth() {
    const containerBBox = this.slider.container.getBoundingClientRect();
    const slideBBox = this.node.getBoundingClientRect();
    const maxLeft = Math.max(containerBBox.x, slideBBox.x);
    const minRight = Math.min(containerBBox.x + containerBBox.width, slideBBox.x + slideBBox.width);
    return minRight - maxLeft;
  }
}